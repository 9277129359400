import request from '@/utils/requestV2'

// rent_type  招租方式
// rs_invest_status 招商项目状态
export function listDictLabel (data) {
  return request({
    url: '/ooh-invest/v1/dict/listDictLabel',
    method: 'get',
    params: data
  })
}
