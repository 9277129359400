import request from '@/utils/requestV2'
const qs = require('qs')

// 查询合同列表
export function listContractInfo (data) {
  return request({
    url: '/ooh-invest/v1/contract/listContractInfo',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据合同id获取合同的信息
export function getContractInfo (data) {
  return request({
    url: '/ooh-invest/v1/contract/getContractInfo',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询合同的资产列表
export function listResourceAndFee (data) {
  return request({
    url: '/ooh-invest/v1/contract/listResourceAndFee',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 查询合同的标的物列表-显示费用列表
export function listObjectAndFee (data) {
  return request({
    url: '/ooh-invest/v1/contract/listObjectAndFee',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询合同的费用列表
export function listObjectFee (data) {
  return request({
    url: '/ooh-invest/v1/contract/listObjectFee',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 生成租金配置方案
export function createFeePlan (data) {
  return request({
    url: '/ooh-invest/v1/contract/createFeePlan',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 保存标的物的费用明细
export function saveObjectFeeList (data) {
  return request({
    url: '/ooh-invest/v1/contract/saveObjectFeeList',
    method: 'post',
    data: data,
    headers: { 'Content-Type': 'application/json' }
  })
}

// 增加合同标的物品-租赁空间
export function addObjectBySpace (data) {
  return request({
    url: '/ooh-invest/v1/contract/addObjectBySpace',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 删除合同的标的物
export function deleteObject (data) {
  return request({
    url: '/ooh-invest/v1/contract/deleteObject',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除合同的标的物-通过租赁空间删除
export function deleteObjectBySpace (data) {
  return request({
    url: '/ooh-invest/v1/contract/deleteObjectBySpace',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 自定义合同编码
export function updateContractCode (data) {
  return request({
    url: '/ooh-invest/v1/contract/updateContractCode',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 保存合同各种日期
export function addContractDate (data) {
  return request({
    url: '/ooh-invest/v1/contract/addContractDate',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 查询合同的资产费用列表
export function listResourceFee (data) {
  return request({
    url: '/ooh-invest/v1/contract/listResourceFee',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 创建资产的费用明细
export function createDeviceFee (data) {
  return request({
    url: '/ooh-invest/v1/contract/createDeviceFee',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 创建资产的费用明细
export function saveOrUpdateResourceFeeList (params) {
  return request({
    url: '/ooh-invest/v1/contract/saveOrUpdateResourceFeeList',
    method: 'post',
    data: params,
    headers: { 'Content-Type': 'application/json' }
  })
}
// 查询合同的免租期列表
export function listFreePeriod (data) {
  return request({
    url: '/ooh-invest/v1/contract/listFreePeriod',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除合同的免租期
export function deleteFreePeriod (data) {
  return request({
    url: '/ooh-invest/v1/contract/deleteFreePeriod',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 添加合同的免租期
export function addFreePeriod (data) {
  return request({
    url: '/ooh-invest/v1/contract/addFreePeriod',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 配置合同的押金和运营管理费
export function savePayAmount (data) {
  return request({
    url: '/ooh-invest/v1/contract/savePayAmount',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 配置合同的付款周期
export function savePayCycle (data) {
  return request({
    url: '/ooh-invest/v1/contract/savePayCycle',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取合同附件列表
export function getFileList (data) {
  return request({
    url: '/ooh-invest/v1/contract/getFileList',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 删除合同附件列表
export function deleteFile (data) {
  return request({
    url: '/ooh-invest/v1/contract/deleteFile',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 上传合同附件
export function uploadFilePath (data) {
  return request({
    url: '/ooh-invest/v1/contract/uploadFilePath',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 提交合同草稿为有效合同
export function submitContract (data) {
  return request({
    url: '/ooh-invest/v1/contract/submitContract',
    method: 'post',
    data: qs.stringify(data)
  })
}
